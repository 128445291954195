<script setup>
	import { onBeforeUnmount, ref, shallowRef, onMounted } from 'vue'
	import { ElMessage } from 'element-plus'
	import axios from 'axios';
	import { useRoute, useRouter } from 'vue-router'
	import { useMemberStore } from '../../../stores';
	const userStore = useMemberStore();
	const route = useRoute();
	const router = useRouter();
	const baseURL = 'https://mini.walkertour.cn';
	const rootPath = "/www/wwwroot/miniServer/server/dist";
	const headers = ref({
		'Authorization': `${userStore.profile?.token}`,
	})
	const size = ref('default')
	const labelPosition = ref('right')
	const sizeForm = ref({
		bt:{'中文':'','韩文':'','英文':''},//姓名
		nr:{'中文':'','韩文':'','英文':''},//职称
		create:'',//添加时间
		coverimage:'',//头像
	});
	onMounted(async()=>{
		const response = await axios.post('/news/info/queryInfo', {
		  billid:route.query.billid,
		}, {
		  headers: {
			'Content-Type': 'application/json',
			Authorization: `${userStore.profile?.token}`,
		  }
		});
		if(response.data.status===0){
			sizeForm.value['bt'] = response.data.btValues
			sizeForm.value['nr'] = response.data.nrValues
			if(response.data.gjcValues){
				gjcValue.value = JSON.parse(response.data.gjcValues)
			}
			coverFileList.value = response.data.coverimage
		}
	})
	const coverFileList = ref([])
	const onBack=()=> {
		router.go(-1)
	}
	const coverData = ref({
		billid:`${userStore.profile?.userid}`,
		type:'coverimage'
	})
	const handleCoverSuccess= (
	  response,
	  uploadFile
	) => {
	  if(response.status===0){
		sizeForm.value['coverimage'] = response.imgUrl.replace(rootPath,baseURL);
	  }
	}
	
	const beforeCoverUpload = (rawFile) => {
	  if (rawFile.type !== 'image/jpg'&&rawFile.type !== 'image/jpeg'&&rawFile.type !== 'image/png') {
	    ElMessage.error('Avatar picture must be JPG/PNG format!')
	    return false
	  } else if (rawFile.size / 1024 / 1024 > 12) {
	    ElMessage.error('Avatar picture size can not exceed 12MB!')
	    return false
	  }
	  return true
	}
	const handleCoverRemove=(file,uploadFiles)=>{
		sizeForm.value['coverimage'] = '';
	}
	
	//标题
	const optionsBt = ref(['中文','韩文','英文'])
	const triggerBt = ref('中文')
	//描述
	const optionsMs = ref(['中文','韩文','英文'])
	const triggerMs = ref('中文')
	//关键词
	const optionsGjc = ref(['中文','韩文','英文'])
	const triggerGjc = ref('中文')
	const gjcValue = ref({'中文':[],'韩文':[],'英文':[]})
</script>
<template>
	<div style="height: 800px;overflow-y: scroll;">
		<el-form
		    ref="form"
		    :model="sizeForm"
		    label-width="auto"
		    :label-position="labelPosition"
		    :size="size"
		>
			<div style="display: flex;">
				<el-form-item label="封面" style="width: 92%;">
					<el-upload
						class="avatar-uploader"
						:limit="1"
						disabled
						v-model:file-list="coverFileList"
						list-type="picture-card"
						action="https://server.walkertour.cn/news/info/uploadFile"
						:headers="headers"
						:data="coverData"
						:on-success="handleCoverSuccess"
						:before-upload="beforeCoverUpload"
						:on-remove="handleCoverRemove"
						crossorigin="anonymous"
					>
						<el-icon class="avatar-uploader-icon"><Plus /></el-icon>
					</el-upload>
				</el-form-item>
			</div>
			<div style="display: flex;" >
				<el-form-item label="标题" style="width: 92%;">
				  <div>
				  	<el-segmented v-model="triggerBt" :options="optionsBt" />
				  </div>
				  <br />
				  <el-input disabled class="w-50 m-2" v-model="sizeForm.bt[`${triggerBt}`]" />
				</el-form-item>
			</div>
			<div style="display: flex;" >
				<el-form-item label="内容" style="width: 92%;">
				  <div>
				  	<el-segmented v-model="triggerMs" :options="optionsMs" />
				  </div>
				  <br />
				  <el-input disabled :autosize="{ minRows: 8, maxRows: 16 }" type="textarea" class="w-50 m-2" v-model="sizeForm.nr[`${triggerMs}`]" />
				</el-form-item>
			</div>
			<div style="display: flex;">
				<el-form-item label="关键词" style="width: 92%;">
					<div>
						<el-segmented v-model="triggerGjc" :options="optionsGjc" />
					</div>
					<br />
					<el-input-tag disabled v-model="gjcValue[`${triggerGjc}`]" trigger="Space" placeholder="请输入" />
				</el-form-item>
			</div>
			<div style="display: flex;justify-content: center;">
				<el-form-item>
			      <el-button type="primary" @click="onBack">保存</el-button>
			    </el-form-item>
			</div>
		</el-form>
	</div>
</template>
<style scoped>
	.avatar-uploader .avatar {
	  width: 128px;
	  height: 128px;
	  display: block;
	}
	.avatar-uploader .el-upload {
	  border: 1px dashed var(--el-border-color);
	  border-radius: 6px;
	  cursor: pointer;
	  position: relative;
	  overflow: hidden;
	  transition: var(--el-transition-duration-fast);
	}
	.avatar-uploader .el-upload:hover {
	  border-color: var(--el-color-primary);
	}
	.el-icon.avatar-uploader-icon {
	  font-size: 28px;
	  color: #8c939d;
	  width: 178px;
	  height: 178px;
	  text-align: center;
	}
</style>