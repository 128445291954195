<template>
	<div class="search-jsj-tatle">
		<div style="display: flex;">
			<el-input
			  v-model="key"
			  placeholder="请输入搜索关键词"
			  class="input-with-select"
			>
			  <template #append>
			    <el-button @click="fetchOrders" icon="Search" />
			  </template>
			</el-input>
		</div>
	</div>
	<el-table :data="tableData" style="width: 100%" height="480">
	    <el-table-column fixed prop="comment" label="评论" width="800" />
	    <el-table-column fixed prop="lx" label="类型" width="100" />
	    <el-table-column prop="rate" label="评分" width="100" />
		<el-table-column prop="sfzj" label="是否注解" width="120" />
		<el-table-column prop="createdate" label="创建日期" width="200" />
	    <el-table-column fixed="right" label="操作" width="*" class-name="operate-style-sss">
	      <template #default="scope">
	        <el-button link type="primary" size="small" @click="handleDtClick(scope.row)">注解</el-button>
			<el-button link type="primary" size="small" @click="deleteData(scope.row)">删除</el-button>
	      </template>
	    </el-table-column>
	</el-table>
	<div class="pagination-custom">
		<el-pagination :page-size="20" @current-change="changePageNo" background layout="pager" :total="total" />
	</div>
	<el-dialog
	    v-model="bzDialogVisible"
	    title="添加注解"
	    width="50%"
	    destroy-on-close
	    center
		:modal="false"
	>	
		<el-form
			ref="form"
			:model="sizeForm"
			label-width="auto"
			label-position="right"
			size="default"
		>
			<div>
				<el-text class="mx-1">原文：{{yw}}</el-text>
			</div>
			<div style="display: flex;" >
				<el-form-item label="注解" style="width: 92%;">
				  <div>
				  	<el-segmented v-model="triggerZj" :options="optionsZj" />
				  </div>
				  <br />
				  <el-input class="w-50 m-2" v-model="zj[`${triggerZj}`]" />
				</el-form-item>
			</div>
		</el-form>
		<template #footer>
		  <span class="dialog-footer">
		    <el-button @click="bzDialogVisible = false">关闭</el-button>
		    <el-button type="primary" @click="saveBz">保存</el-button>
		  </span>
		</template>
	</el-dialog>
</template>

<script setup>
	import { ref,provide,inject,onMounted } from 'vue';
	import axios from 'axios';
	import { useMemberStore } from '../../../stores';
	import { ElMessage } from 'element-plus'
	import { useRoute, useRouter } from 'vue-router'
	const router = useRouter();
	const userStore = useMemberStore();
	const pageNo = ref(1);
	const pageSize = ref(20);
	const total = ref(0);
	const sizeForm = ref({})
	const bzDialogVisible = ref(false)
	const zj = ref({'中文':'','韩文':'','英文':''})
	const optionsZj = ref(['中文','英文'])
	const triggerZj = ref('中文')
	const yw = ref('')
	const saveBz=async()=>{
		const response = await axios.post('/comments/info/saveNote', {
		  billid:billid.value,
		  contentItem:JSON.stringify(zj.value)
		}, {
		  headers: {
		    'Content-Type': 'application/json',
			Authorization: `${userStore.profile?.token}`,
		  }
		});
		if(response.data.status===0){
			ElMessage({
				message: '注解成功!',
				type: 'success',
			})
			fetchOrders()
		}
		bzDialogVisible.value = false
		zj.value = {'中文':'','韩文':'','英文':''}
	}
	const key = ref('');
	const tableData = ref([]);
	const billid = ref('');
	onMounted(async()=>{
		const response = await axios.post('/comments/info/queryComments', {
		  pageNo:pageNo.value,
		  pageSize:pageSize.value,
		}, {
		  headers: {
		    'Content-Type': 'application/json',
			Authorization: `${userStore.profile?.token}`,
		  }
		});
		if(response.data.status===0){
			tableData.value = response.data.data;
			total.value = response.data.total;
		}
	})
	const fetchOrders = async () => {
		const response = await axios.post('/comments/info/queryComments', {
		  key:'comment',
		  value:key.value,
		  pageNo:pageNo.value,
		  pageSize:pageSize.value,
		}, {
		  headers: {
		    'Content-Type': 'application/json',
			Authorization: `${userStore.profile?.token}`,
		  }
		});
		if(response.data.status===0){
			tableData.value = response.data.data;
			total.value = response.data.total;
		}else{
			tableData.value = [];
			total.value = 0;
		}
	};
	const handleDtClick = (e)=>{
		billid.value = e.id;
		yw.value = e.comment
		bzDialogVisible.value = true
	}
	const deleteData=async(e)=>{
		const response = await axios.post('/comments/info/delete', {
		  billid:e.id,
		}, {
		  headers: {
		    'Content-Type': 'application/json',
			Authorization: `${userStore.profile?.token}`,
		  }
		});
		if(response.data.status===0){
			ElMessage({
				message: '删除成功!',
				type: 'success',
			})
			fetchOrders()
		}
	}
	const changePageNo = (val) => {
		pageNo.value = val;
		fetchOrders();
	}
</script>

<style scoped>
	.pagination-custom{
		display: flex;
		justify-content: flex-end;
		align-items: center;
		margin: 10px 0;
	}
	.search-jsj-tatle{
		float: right;
		width: 50%;
		margin: 0 0 10px 0;
	}
	.dialog-cond-dt{
		margin: 0 0 0 20px;
	}
	.dialog-cond-label{
		margin: 0 5px;
	}
</style>