<script setup>
	import { onBeforeUnmount, ref, shallowRef, onMounted } from 'vue'
	import { ElMessage } from 'element-plus'
	import axios from 'axios';
	import { useMemberStore } from '../../../stores';
	const userStore = useMemberStore();
	const baseURL = 'https://mini.walkertour.cn';
	const rootPath = "/www/wwwroot/miniServer/server/dist";
	const headers = ref({
		'Authorization': `${userStore.profile?.token}`,
	})
	//热门景点
	const options = ref([])
	const size = ref('default')
	const labelPosition = ref('right')
	const sizeForm = ref({
		bt:{'中文':'','韩文':'','英文':''},//标题 
		ms:{'中文':'','韩文':'','英文':''},//描述
		dz:{'中文':'','韩文':'','英文':''},//地址信息
		dt:{'中文':'','韩文':'','英文':''},//地图图片
		create:'',//添加时间
		coverImage:'',//封面
		fjrmjd:''
	});
	onMounted(async()=>{
		const response = await axios.post('/scene/info/queryInfoList', {
		  pageSize:1000,
		  pageNo:1
		}, {
		  headers: {
			'Content-Type': 'application/json',
			Authorization: `${userStore.profile?.token}`,
		  }
		});
		if(response.data.status===0){
			options.value = []
			response.data.data.map(item=>{
				options.value.push({
					label:item.bt,
					value:item.id
				})
			})
		}
	})
	const fileList = ref([])
	const coverFileList = ref([])
	const onSubmit=async()=> {
		const date = new Date();
		const year = date.getFullYear();
		const month = (date.getMonth() + 1)<10?"0"+(date.getMonth() + 1):(date.getMonth() + 1);
		const day = date.getDate()<10?"0"+date.getDate():date.getDate();
		const hours = date.getHours()<10?"0"+date.getHours():date.getHours();
		const minutes = date.getMinutes()<10?"0"+date.getMinutes():date.getMinutes();
		const seconds = date.getSeconds()<10?"0"+date.getSeconds():date.getSeconds();
		sizeForm.value['create'] =  year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
		const response = await axios.post('/restaurant/info/saveOrUpdate', {
		  sizeForm:JSON.stringify(sizeForm.value),
		  menuItem:JSON.stringify(menuItems.value),
		  gzsjValue:JSON.stringify(gzsjValue.value),
		  gjcValue:JSON.stringify(gjcValue.value)
		}, {
		  headers: {
			'Content-Type': 'application/json',
			Authorization: `${userStore.profile?.token}`,
		  }
		});
		if(response.data.status===0){
			ElMessage.success('添加成功!');
			sizeForm.value = {
				bt:{'中文':'','韩文':'','英文':''},//标题
				ms:{'中文':'','韩文':'','英文':''},//描述
				dz:{'中文':'','韩文':'','英文':''},//地址信息
				dt:{'中文':'','韩文':'','英文':''},//地图图片
				create:'',//添加时间
				coverImage:'',//封面
				fjrmjd:''
			}
			menuItems.value = {'中文':[],'韩文':[],'英文':[]}
			gzsjValue.value = {'中文':[],'韩文':[],'英文':[]}
			gjcValue.value = {'中文':[],'韩文':[],'英文':[]}
			fileList.value = {'中文':[],'韩文':[],'英文':[]}
			coverFileList.value = []
		}
	}
	const data = ref({
		billid:`${userStore.profile?.userid}`
	})
	const coverData = ref({
		billid:`${userStore.profile?.userid}`,
		type:'coverImage'
	})
	const handleCoverSuccess= (
	  response,
	  uploadFile
	) => {
	  if(response.status===0){
		sizeForm.value['coverImage'] = response.imgUrl.replace(rootPath,baseURL);
	  }
	}
	
	const beforeCoverUpload = (rawFile) => {
	  if (rawFile.type !== 'image/jpg'&&rawFile.type !== 'image/jpeg'&&rawFile.type !== 'image/png') {
	    ElMessage.error('Avatar picture must be JPG/PNG format!')
	    return false
	  } else if (rawFile.size / 1024 / 1024 > 12) {
	    ElMessage.error('Avatar picture size can not exceed 12MB!')
	    return false
	  }
	  return true
	}
	const handleCoverRemove=(file,uploadFiles)=>{
		sizeForm.value['coverImage'] = '';
	}
	
	const handleAvatarSuccess= (
	  response,
	  uploadFile
	) => {
	  if(response.status===0){
		sizeForm.value['dt'][`${triggerDt.value}`] = response.imgUrl.replace(rootPath,baseURL);
	  }
	}
	
	const beforeAvatarUpload = (rawFile) => {
	  if (rawFile.type !== 'image/jpg'&&rawFile.type !== 'image/jpeg'&&rawFile.type !== 'image/png') {
	    ElMessage.error('Avatar picture must be JPG/PNG format!')
	    return false
	  } else if (rawFile.size / 1024 / 1024 > 12) {
	    ElMessage.error('Avatar picture size can not exceed 12MB!')
	    return false
	  }
	  return true
	}
	const handleRemove=(file,uploadFiles)=>{
		sizeForm.value['dt'][`${triggerDt.value}`] = '';
	}
	//标题
	const optionsBt = ref(['中文','韩文','英文'])
	const triggerBt = ref('中文')
	//描述
	const optionsMs = ref(['中文','韩文','英文'])
	const triggerMs = ref('中文')
	//地址
	const optionsDz = ref(['中文','韩文','英文'])
	const triggerDz = ref('中文')
	//地图
	const optionsDt = ref(['中文','韩文','英文'])
	const triggerDt = ref('中文')
	//关键词 用于搜索
	const optionsGjc = ref(['中文','韩文','英文'])
	const triggerGjc = ref('中文')
	const gjcValue = ref({'中文':[],'韩文':[],'英文':[]})
	//spa项目
	const optionsScxm = ref(['中文','韩文','英文'])
	const triggerScxm = ref('中文')
	const menuItems = ref({'中文':[],'韩文':[],'英文':[]})
	const addSPaInfo=()=>{
		menuItems.value[`${triggerScxm.value}`].push({
			name:'',
			desc:''
		})
	}
	//交通工具
	const optionsAxylfw = ref(['中文','韩文','英文'])
	const triggerAxylfw = ref('中文')
	const gzsjValue = ref({'中文':[],'韩文':[],'英文':[]}) 
	const addJtgj=()=>{
		gzsjValue.value[`${triggerAxylfw.value}`].push({
			name:'',
			desc:''
		})
	}
</script>
<template>
	<div style="height: 800px;overflow-y: scroll;">
		<el-form
		    ref="form"
		    :model="sizeForm"
		    label-width="auto"
		    :label-position="labelPosition"
		    :size="size"
		>
			<div style="display: flex;">
				<el-form-item label="封面" style="width: 92%;">
					<el-upload
						class="avatar-uploader"
						:limit="1"
						v-model:file-list="coverFileList"
						list-type="picture-card"
						action="https://server.walkertour.cn/restaurant/info/uploadFile"
						:headers="headers"
						:data="coverData"
						:on-success="handleCoverSuccess"
						:before-upload="beforeCoverUpload"
						:on-remove="handleCoverRemove"
						crossorigin="anonymous"
					>
						<el-icon class="avatar-uploader-icon"><Plus /></el-icon>
					</el-upload>
				</el-form-item>
			</div>
			<div style="display: flex;" >
				<el-form-item label="标题" style="width: 92%;">
				  <div>
				  	<el-segmented v-model="triggerBt" :options="optionsBt" />
				  </div>
				  <br />
				  <el-input class="w-50 m-2" v-model="sizeForm.bt[`${triggerBt}`]" />
				</el-form-item>
			</div>
			<div style="display: flex;" >
				<el-form-item label="描述" style="width: 92%;">
				  <div>
				  	<el-segmented v-model="triggerMs" :options="optionsMs" />
				  </div>
				  <br />
				  <el-input class="w-50 m-2" v-model="sizeForm.ms[`${triggerMs}`]" />
				</el-form-item>
			</div>
			<div style="display: flex;" >
				<el-form-item label="地址" style="width: 92%;">
				  <div>
				  	<el-segmented v-model="triggerDz" :options="optionsDz" />
				  </div>
				  <br />
				  <el-input class="w-50 m-2" v-model="sizeForm.dz[`${triggerDz}`]" />
				</el-form-item>
			</div>
			<div style="display: flex;">
				<el-form-item label="地图" style="width: 92%;">
					<div>
						<el-segmented v-model="triggerDt" :options="optionsDt" />
					</div>
					<br />
					<el-upload
						class="avatar-uploader"
						:limit="1"
						v-model:file-list="fileList[`${triggerDt}`]"
						list-type="picture-card"
						action="https://server.walkertour.cn/restaurant/info/uploadFile"
						:headers="headers"
						:data="data"
						:on-success="handleAvatarSuccess"
						:before-upload="beforeAvatarUpload"
						:on-remove="handleRemove"
						crossorigin="anonymous"
					>
						<el-icon class="avatar-uploader-icon"><Plus /></el-icon>
					</el-upload>
				</el-form-item>
			</div>
			<div style="display: flex;">
				<el-form-item label="关键词(用于搜索)" style="width: 92%;">
					<div>
						<el-segmented v-model="triggerGjc" :options="optionsGjc" />
					</div>
					<br />
					<el-input-tag  tag-type="primary" v-model="gjcValue[`${triggerGjc}`]" trigger="Space" placeholder="请输入" />
				</el-form-item>
			</div>
			<div>
				<el-form-item label="菜单" style="width: 92%;" >
					<div>
						<el-segmented v-model="triggerScxm" :options="optionsScxm" />&nbsp;<el-button type="primary" @click="addSPaInfo">新增</el-button>
					</div>
				</el-form-item>
			</div>
			<div v-for="(input, index) in menuItems[`${triggerScxm}`]" :key="index" style="margin-top: 10px;width:92%;display: flex;align-items: center;">
			  <el-text class="mx-1" type="primary" style="width: 96px;">序号{{index+1}}</el-text>
			  <el-input v-model="input.name" placeholder="请输入菜单名称"></el-input>&nbsp;
			  <el-input v-model="input.desc" placeholder="请输入菜单描述"></el-input>&nbsp;
			  <el-icon><Minus /></el-icon>
			</div>
			<div>
				<el-form-item label="营业时间" style="width: 92%;">
					<div>
						<el-segmented v-model="triggerAxylfw" :options="optionsAxylfw" />&nbsp;<el-button type="primary" @click="addJtgj">新增</el-button>
					</div>
				</el-form-item>
			</div>
			<div v-for="(input, index) in gzsjValue[`${triggerAxylfw}`]" :key="index" style="margin-top: 10px;width:92%;display: flex;align-items: center;">
				<el-text class="mx-1" type="primary" style="width: 96px;">序号{{index+1}}</el-text>
				<el-input v-model="input.name" placeholder="请输入星期"></el-input>&nbsp;&nbsp;
				<el-input v-model="input.desc" placeholder="请输入时间段"></el-input>&nbsp;
				<el-icon><Minus /></el-icon>
			</div>
			<div style="display: flex;" >
				<el-form-item label="附近热门景点" style="width: 92%;">
				  <el-select
				      v-model="sizeForm.fjrmjd"
				      clearable
					  filterable
					  multiple
				      placeholder="请选择"
				      style="width: 240px"
				    >
				      <el-option
				        v-for="item in options"
				        :key="item.value"
				        :label="item.label"
				        :value="item.value"
				      />
				    </el-select>
				</el-form-item>
			</div>
			<div style="display: flex;justify-content: center;">
				<el-form-item>
			      <el-button type="primary" @click="onSubmit">保存</el-button>
			    </el-form-item>
			</div>
		</el-form>
	</div>
</template>
<style scoped>
	.avatar-uploader .avatar {
	  width: 128px;
	  height: 128px;
	  display: block;
	}
	.avatar-uploader .el-upload {
	  border: 1px dashed var(--el-border-color);
	  border-radius: 6px;
	  cursor: pointer;
	  position: relative;
	  overflow: hidden;
	  transition: var(--el-transition-duration-fast);
	}
	.avatar-uploader .el-upload:hover {
	  border-color: var(--el-color-primary);
	}
	.el-icon.avatar-uploader-icon {
	  font-size: 28px;
	  color: #8c939d;
	  width: 178px;
	  height: 178px;
	  text-align: center;
	}
</style>