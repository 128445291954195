<script setup>
	import { onBeforeUnmount, ref, shallowRef, onMounted } from 'vue'
	import { ElMessage } from 'element-plus'
	import axios from 'axios';
	import { useMemberStore } from '../../../stores';
	import { useRoute, useRouter } from 'vue-router'
	const route = useRoute();
	const router = useRouter();
	const userStore = useMemberStore();
	const baseURL = 'https://mini.walkertour.cn';
	const rootPath = "/www/wwwroot/miniServer/server/dist";
	const headers = ref({
		'Authorization': `${userStore.profile?.token}`,
	})
	const size = ref('default')
	const labelPosition = ref('right')
	const sizeForm = ref({
		bt:{'中文':'','韩文':'','英文':''},//标题 
		ms:{'中文':'','韩文':'','英文':''},//描述
		dz:{'中文':'','韩文':'','英文':''},//地址信息
		dt:{'中文':'','韩文':'','英文':''},//地图图片
		create:'',//添加时间
		coverImage:'',//封面
	});
	onMounted(async()=>{
		const response = await axios.post('/scene/info/queryInfo', {
		  billid:route.query.billid,
		}, {
		  headers: {
			'Content-Type': 'application/json',
			Authorization: `${userStore.profile?.token}`,
		  }
		});
		if(response.data.status===0){
			sizeForm.value['bt'] = response.data.btValues
			sizeForm.value['ms'] = response.data.msValues
			sizeForm.value['dz'] = response.data.dzValues
			fileList.value = response.data.dtValues
			coverFileList.value = response.data.coverImage
			jtgjValue.value = response.data.jtgjValues
		}
	})
	const fileList = ref({'中文':[],'韩文':[],'英文':[]})
	const coverFileList = ref([])
	const onSubmit=async()=> {
		router.go(-1)
	}
	const data = ref({
		billid:`${userStore.profile?.userid}`
	})
	const coverData = ref({
		billid:`${userStore.profile?.userid}`,
		type:'coverImage'
	})
	const handleAvatarSuccess= (
	  response,
	  uploadFile
	) => {
	  if(response.status===0){
		sizeForm.value['dt'][`${triggerDt.value}`] = response.imgUrl.replace(rootPath,baseURL);
	  }
	}
	
	const beforeAvatarUpload = (rawFile) => {
	  if (rawFile.type !== 'image/jpg'&&rawFile.type !== 'image/jpeg'&&rawFile.type !== 'image/png') {
	    ElMessage.error('Avatar picture must be JPG/PNG format!')
	    return false
	  } else if (rawFile.size / 1024 / 1024 > 12) {
	    ElMessage.error('Avatar picture size can not exceed 12MB!')
	    return false
	  }
	  return true
	}
	const handleRemove=(file,uploadFiles)=>{
		sizeForm.value['dt'][`${triggerDt.value}`] = '';
	}
	
	const handleCoverSuccess= (
	  response,
	  uploadFile
	) => {
	  if(response.status===0){
		sizeForm.value['coverImage'] = response.imgUrl.replace(rootPath,baseURL);
	  }
	}
	
	const beforeCoverUpload = (rawFile) => {
	  if (rawFile.type !== 'image/jpg'&&rawFile.type !== 'image/jpeg'&&rawFile.type !== 'image/png') {
	    ElMessage.error('Avatar picture must be JPG/PNG format!')
	    return false
	  } else if (rawFile.size / 1024 / 1024 > 12) {
	    ElMessage.error('Avatar picture size can not exceed 12MB!')
	    return false
	  }
	  return true
	}
	const handleCoverRemove=(file,uploadFiles)=>{
		sizeForm.value['coverImage'] = '';
	}
	
	//标题
	const optionsBt = ref(['中文','韩文','英文'])
	const triggerBt = ref('中文')
	//描述
	const optionsMs = ref(['中文','韩文','英文'])
	const triggerMs = ref('中文')
	//地址
	const optionsDz = ref(['中文','韩文','英文'])
	const triggerDz = ref('中文')
	//地图
	const optionsDt = ref(['中文','韩文','英文'])
	const triggerDt = ref('中文')
	//交通工具
	const optionsAxylfw = ref(['中文','韩文','英文'])
	const triggerAxylfw = ref('中文')
	const jtgjValue = ref({'中文':[],'韩文':[],'英文':[]}) 
	const addJtgj=()=>{
		jtgjValue.value[`${triggerAxylfw.value}`].push({
			type:'',
			value:''
		})
	}
</script>
<template>
	<div style="height: 800px;overflow-y: scroll;margin: 20px 0 0 20px;">
		<el-form
		    ref="form"
		    :model="sizeForm"
		    label-width="auto"
		    :label-position="labelPosition"
		    :size="size"
		>
			<div style="display: flex;">
				<el-form-item label="封面" style="width: 92%;">
					<el-upload
						class="avatar-uploader"
						:limit="1"
						disabled
						v-model:file-list="coverFileList"
						list-type="picture-card"
						action="https://server.walkertour.cn/scene/info/uploadFile"
						:headers="headers"
						:data="coverData"
						:on-success="handleCoverSuccess"
						:before-upload="beforeCoverUpload"
						:on-remove="handleCoverRemove"
						crossorigin="anonymous"
					>
						<el-icon class="avatar-uploader-icon"><Plus /></el-icon>
					</el-upload>
				</el-form-item>
			</div>
			<div style="display: flex;" >
				<el-form-item label="标题" style="width: 92%;">
				  <div>
				  	<el-segmented v-model="triggerBt" :options="optionsBt" />
				  </div>
				  <br />
				  <el-input disabled class="w-50 m-2" v-model="sizeForm.bt[`${triggerBt}`]" />
				</el-form-item>
			</div>
			<div style="display: flex;" >
				<el-form-item label="描述" style="width: 92%;">
				  <div>
				  	<el-segmented v-model="triggerMs" :options="optionsMs" />
				  </div>
				  <br />
				  <el-input disabled class="w-50 m-2" v-model="sizeForm.ms[`${triggerMs}`]" />
				</el-form-item>
			</div>
			<div style="display: flex;" >
				<el-form-item label="地址" style="width: 92%;">
				  <div>
				  	<el-segmented v-model="triggerDz" :options="optionsDz" />
				  </div>
				  <br />
				  <el-input disabled class="w-50 m-2" v-model="sizeForm.dz[`${triggerDz}`]" />
				</el-form-item>
			</div>
			<div style="display: flex;">
				<el-form-item label="地图" style="width: 92%;">
					<div>
						<el-segmented v-model="triggerDt" :options="optionsDt" />
					</div>
					<br />
					<el-upload
						class="avatar-uploader"
						:limit="1"
						disabled
						v-model:file-list="fileList[`${triggerDt}`]"
						list-type="picture-card"
						action="https://server.walkertour.cn/scene/info/uploadFile"
						:headers="headers"
						:data="data"
						:on-success="handleAvatarSuccess"
						:before-upload="beforeAvatarUpload"
						:on-remove="handleRemove"
						crossorigin="anonymous"
					>
						<el-icon class="avatar-uploader-icon"><Plus /></el-icon>
					</el-upload>
				</el-form-item>
			</div>
			<div>
				<el-form-item label="交通工具" style="width: 92%;">
					<div>
						<el-segmented v-model="triggerAxylfw" :options="optionsAxylfw" />&nbsp;<el-button type="primary" @click="addJtgj">新增</el-button>
					</div>
				</el-form-item>
			</div>
			<div v-for="(input, index) in jtgjValue[`${triggerAxylfw}`]" :key="index" style="margin-top: 10px;width:92%;display: flex;align-items: center;">
				<el-text class="mx-1" type="primary" style="width: 96px;">序号{{index+1}}</el-text>
				<el-input disabled :placeholder="input"></el-input>
			</div>
			<div style="display: flex;justify-content: center;">
				<el-form-item>
			      <el-button type="primary" @click="onSubmit">返回</el-button>
			    </el-form-item>
			</div>
		</el-form>
	</div>
</template>
<style scoped>
	.avatar-uploader .avatar {
	  width: 128px;
	  height: 128px;
	  display: block;
	}
	.avatar-uploader .el-upload {
	  border: 1px dashed var(--el-border-color);
	  border-radius: 6px;
	  cursor: pointer;
	  position: relative;
	  overflow: hidden;
	  transition: var(--el-transition-duration-fast);
	}
	.avatar-uploader .el-upload:hover {
	  border-color: var(--el-color-primary);
	}
	.el-icon.avatar-uploader-icon {
	  font-size: 28px;
	  color: #8c939d;
	  width: 178px;
	  height: 178px;
	  text-align: center;
	}
</style>