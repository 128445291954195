<script setup>
	import { onBeforeUnmount, ref, shallowRef, onMounted } from 'vue'
	import { ElMessage } from 'element-plus'
	import axios from 'axios';
	import { useMemberStore } from '../../../stores';
	const userStore = useMemberStore();
	const baseURL = 'https://mini.walkertour.cn';
	const rootPath = "/www/wwwroot/miniServer/server/dist";
	const headers = ref({
		'Authorization': `${userStore.profile?.token}`,
	})
	//在医生库中选择医生
	const options = ref([])
	const size = ref('default')
	const labelPosition = ref('right')
	const sizeForm = ref({
		bt:{'中文':'','韩文':'','英文':''},//标题 
		ms:{'中文':'','韩文':'','英文':''},//描述
		dz:{'中文':'','韩文':'','英文':''},//地址信息
		dt:{'中文':'','韩文':'','英文':''},//地图图片
		ysjs:'',//绑定医生ID
		create:'',//添加时间
		coverImage:'',//封面
		phone:'',
		email:'',
		company:'',
		business:'',
		address:''
	});
	onMounted(async()=>{
		const response = await axios.post('/doctor/info/queryInfoList', {
		  pageSize:1000,
		  pageNo:1
		}, {
		  headers: {
			'Content-Type': 'application/json',
			Authorization: `${userStore.profile?.token}`,
		  }
		});
		if(response.data.status===0){
			options.value = []
			response.data.data.map(item=>{
				options.value.push({
					label:item.xm,
					value:item.id
				})
			})
		}
	})
	const fileList = ref({'中文':[],'韩文':[],'英文':[]})
	const coverFileList = ref([])
	const onSubmit=async()=> {
		const date = new Date();
		const year = date.getFullYear();
		const month = (date.getMonth() + 1)<10?"0"+(date.getMonth() + 1):(date.getMonth() + 1);
		const day = date.getDate()<10?"0"+date.getDate():date.getDate();
		const hours = date.getHours()<10?"0"+date.getHours():date.getHours();
		const minutes = date.getMinutes()<10?"0"+date.getMinutes():date.getMinutes();
		const seconds = date.getSeconds()<10?"0"+date.getSeconds():date.getSeconds();
		sizeForm.value['create'] =  year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
		const response = await axios.post('/hospital/info/saveOrUpdate', {
		  sizeForm:JSON.stringify(sizeForm.value),
		  scxmValue:JSON.stringify(scxmValue.value),
		  axylfwValue:JSON.stringify(axylfwValue.value),
		  yyznValue:JSON.stringify(yyznValue.value),
		  qbxmValue:JSON.stringify(qbxmValue.value),
		  gzsjItems:JSON.stringify(gzsjItems.value),
		  gjcValue:JSON.stringify(gjcValue.value)
		}, {
		  headers: {
			'Content-Type': 'application/json',
			Authorization: `${userStore.profile?.token}`,
		  }
		});
		if(response.data.status===0){
			ElMessage.success('添加成功!');
			sizeForm.value = {
				bt:{'中文':'','韩文':'','英文':''},//标题
				ms:{'中文':'','韩文':'','英文':''},//描述
				dz:{'中文':'','韩文':'','英文':''},//地址信息
				dt:{'中文':'','韩文':'','英文':''},//地图图片
				ysjs:'',//绑定医生ID
				create:'',//添加时间
				coverImage:'',//封面
				phone:'',
				email:'',
				company:'',
				business:'',
				address:''
			}
			scxmValue.value = {'中文':[],'韩文':[],'英文':[]}
			axylfwValue.value = {'中文':[],'韩文':[],'英文':[]}
			yyznValue.value = {'中文':[],'韩文':[],'英文':[]}
			qbxmValue.value = {'中文':[],'韩文':[],'英文':[]}
			gzsjItems.value = {'中文':[],'韩文':[],'英文':[]}
			gjcValue.value = {'中文':[],'韩文':[],'英文':[]}
			fileList.value = {'中文':[],'韩文':[],'英文':[]}
			coverFileList.value = []
		}
	}
	const coverData = ref({
		billid:`${userStore.profile?.userid}`,
		type:'coverImage'
	})
	const handleCoverSuccess= (
	  response,
	  uploadFile
	) => {
	  if(response.status===0){
		sizeForm.value['coverImage'] = response.imgUrl.replace(rootPath,baseURL);
	  }
	}
	
	const beforeCoverUpload = (rawFile) => {
	  if (rawFile.type !== 'image/jpg'&&rawFile.type !== 'image/jpeg'&&rawFile.type !== 'image/png') {
	    ElMessage.error('Avatar picture must be JPG/PNG format!')
	    return false
	  } else if (rawFile.size / 1024 / 1024 > 12) {
	    ElMessage.error('Avatar picture size can not exceed 12MB!')
	    return false
	  }
	  return true
	}
	const handleCoverRemove=(file,uploadFiles)=>{
		sizeForm.value['coverImage'] = '';
	}
	const data = ref({
		billid:`${userStore.profile?.userid}`
	})
	const handleAvatarSuccess= (
	  response,
	  uploadFile
	) => {
	  if(response.status===0){
		sizeForm.value['dt'][`${triggerDt.value}`] = response.imgUrl.replace(rootPath,baseURL);
	  }
	}
	
	const beforeAvatarUpload = (rawFile) => {
	  if (rawFile.type !== 'image/jpg'&&rawFile.type !== 'image/jpeg'&&rawFile.type !== 'image/png') {
	    ElMessage.error('Avatar picture must be JPG/PNG format!')
	    return false
	  } else if (rawFile.size / 1024 / 1024 > 12) {
	    ElMessage.error('Avatar picture size can not exceed 12MB!')
	    return false
	  }
	  return true
	}
	const handleRemove=(file,uploadFiles)=>{
		sizeForm.value['dt'][`${triggerDt.value}`] = '';
	}
	//标题
	const optionsBt = ref(['中文','韩文','英文'])
	const triggerBt = ref('中文')
	//描述
	const optionsMs = ref(['中文','韩文','英文'])
	const triggerMs = ref('中文')
	//地址
	const optionsDz = ref(['中文','韩文','英文'])
	const triggerDz = ref('中文')
	//地图
	const optionsDt = ref(['中文','韩文','英文'])
	const triggerDt = ref('中文')
	//关键词 用于搜索
	const optionsGjc = ref(['中文','韩文','英文'])
	const triggerGjc = ref('中文')
	const gjcValue = ref({'中文':[],'韩文':[],'英文':[]})
	//擅长项目
	const optionsScxm = ref(['中文','韩文','英文'])
	const triggerScxm = ref('中文')
	const scxmValue = ref({'中文':[],'韩文':[],'英文':[]})
	//安心医疗服务
	const optionsAxylfw = ref(['中文','韩文','英文'])
	const triggerAxylfw = ref('中文')
	const axylfwValue = ref({'中文':[],'韩文':[],'英文':[]}) 
	//预约指南
	const optionsYyzn = ref(['中文','韩文','英文'])  
	const triggerYyzn = ref('中文')
	const yyznValue = ref({'中文':[],'韩文':[],'英文':[]}) 
	//全部项目
	const optionsQbxm = ref(['中文','韩文','英文'])
	const triggerQbxm = ref('中文')
	const qbxmValue = ref({'中文':[],'韩文':[],'英文':[]}) 
	//工作时间
	const optionsGzsj = ref(['中文','韩文','英文'])
	const triggerGzsj = ref('中文')
	const gzsjItems = ref({'中文':[],'韩文':[],'英文':[]}) 
	const addSPaInfo=()=>{
		gzsjItems.value[`${triggerGzsj.value}`].push({
			name:'',
			desc:''
		})
	}
</script>
<template>
	<div style="height: 800px;overflow-y: scroll;">
		<el-form
		    ref="form"
		    :model="sizeForm"
		    label-width="auto"
		    :label-position="labelPosition"
		    :size="size"
		>
			<div style="display: flex;">
				<el-form-item label="封面" style="width: 92%;">
					<el-upload
						class="avatar-uploader"
						:limit="1"
						v-model:file-list="coverFileList"
						list-type="picture-card"
						action="https://server.walkertour.cn/hospital/info/uploadFile"
						:headers="headers"
						:data="coverData"
						:on-success="handleCoverSuccess"
						:before-upload="beforeCoverUpload"
						:on-remove="handleCoverRemove"
						crossorigin="anonymous"
					>
						<el-icon class="avatar-uploader-icon"><Plus /></el-icon>
					</el-upload>
				</el-form-item>
			</div>
			<div style="display: flex;" >
				<el-form-item label="标题" style="width: 92%;">
				  <div>
				  	<el-segmented v-model="triggerBt" :options="optionsBt" />
				  </div>
				  <br />
				  <el-input class="w-50 m-2" v-model="sizeForm.bt[`${triggerBt}`]" />
				</el-form-item>
			</div>
			<div style="display: flex;" >
				<el-form-item label="描述" style="width: 92%;">
				  <div>
				  	<el-segmented v-model="triggerMs" :options="optionsMs" />
				  </div>
				  <br />
				  <el-input class="w-50 m-2" v-model="sizeForm.ms[`${triggerMs}`]" />
				</el-form-item>
			</div>
			<div style="display: flex;" >
				<el-form-item label="地址" style="width: 92%;">
				  <div>
				  	<el-segmented v-model="triggerDz" :options="optionsDz" />
				  </div>
				  <br />
				  <el-input class="w-50 m-2" v-model="sizeForm.dz[`${triggerDz}`]" />
				</el-form-item>
			</div>
			<div style="display: flex;" >
				<el-form-item label="医生" style="width: 92%;">
				  <el-select
				      v-model="sizeForm.ysjs"
				      clearable
					  filterable
					  multiple
				      placeholder="请选择"
				      style="width: 240px"
				    >
				      <el-option
				        v-for="item in options"
				        :key="item.value"
				        :label="item.label"
				        :value="item.value"
				      />
				    </el-select>
				</el-form-item>
			</div>
			<div style="display: flex;">
				<el-form-item label="医院地图" style="width: 92%;">
					<div>
						<el-segmented v-model="triggerDt" :options="optionsDt" />
					</div>
					<br />
					<el-upload
						class="avatar-uploader"
						:limit="1"
						v-model:file-list="fileList[`${triggerDt}`]"
						list-type="picture-card"
						action="https://server.walkertour.cn/hospital/info/uploadFile"
						:headers="headers"
						:data="data"
						:on-success="handleAvatarSuccess"
						:before-upload="beforeAvatarUpload"
						:on-remove="handleRemove"
						crossorigin="anonymous"
					>
						<el-icon class="avatar-uploader-icon"><Plus /></el-icon>
					</el-upload>
				</el-form-item>
			</div>
			<div style="display: flex;" >
				<el-form-item label="联系电话" style="width: 48%;">
				  <el-input class="w-50 m-2" v-model="sizeForm.phone" />
				</el-form-item>
				<el-form-item label="企业邮箱" style="width: 48%;">
				  <el-input class="w-50 m-2" v-model="sizeForm.email" />
				</el-form-item>
			</div>
			<div style="display: flex;" >
				<el-form-item label="公司名称" style="width: 48%;">
				  <el-input class="w-50 m-2" v-model="sizeForm.company" />
				</el-form-item>
				<el-form-item label="营业编号" style="width: 48%;">
				  <el-input class="w-50 m-2" v-model="sizeForm.business" />
				</el-form-item>
			</div>
			<div style="display: flex;" >
				<el-form-item label="公司地址" style="width: 92%;">
				  <el-input class="w-50 m-2" v-model="sizeForm.address" />
				</el-form-item>
			</div>
			<div style="display: flex;">
				<el-form-item label="关键词(用于搜索)" style="width: 92%;">
					<div>
						<el-segmented v-model="triggerGjc" :options="optionsGjc" />
					</div>
					<br />
					<el-input-tag  tag-type="primary" v-model="gjcValue[`${triggerGjc}`]" trigger="Space" placeholder="请输入" />
				</el-form-item>
			</div>
			<div style="display: flex;">
				<el-form-item label="擅长项目" style="width: 92%;">
					<div>
						<el-segmented v-model="triggerScxm" :options="optionsScxm" />
					</div>
					<br />
					<el-input-tag :add-tag="addScxm" :remove-tag="removeScxm" tag-type="primary" v-model="scxmValue[`${triggerScxm}`]" trigger="Space" placeholder="请输入" />
				</el-form-item>
			</div>
			<div style="display: flex;">
				<el-form-item label="安心医疗服务" style="width: 92%;">
					<div>
						<el-segmented v-model="triggerAxylfw" :options="optionsAxylfw" />
					</div>
					<br />
					<el-input-tag :add-tag="addAxylfw" :remove-tag="removeAxylfw" v-model="axylfwValue[`${triggerAxylfw}`]" trigger="Space" placeholder="请输入" />
				</el-form-item>
			</div>
			<div style="display: flex;">
				<el-form-item label="预约指南" style="width: 92%;">
					<div>
						<el-segmented v-model="triggerYyzn" :options="optionsYyzn" />
					</div>
					<br />
					<el-input-tag :add-tag="addYyzn" :remove-tag="removeYyzn" v-model="yyznValue[`${triggerYyzn}`]" trigger="Space" placeholder="请输入" />
				</el-form-item>
			</div>
			<div style="display: flex;">
				<el-form-item label="全部项目" style="width: 92%;">
					<div>
						<el-segmented v-model="triggerQbxm" :options="optionsQbxm" />
					</div>
					<br />
					<el-input-tag :add-tag="addQbxm" :remove-tag="removeQbxm" v-model="qbxmValue[`${triggerQbxm}`]" trigger="Space" placeholder="请输入" />
				</el-form-item>
			</div>
			<div>
				<el-form-item label="工作时间" style="width: 92%;" >
					<div>
						<el-segmented v-model="triggerGzsj" :options="optionsGzsj" />&nbsp;<el-button type="primary" @click="addSPaInfo">新增</el-button>
					</div>
				</el-form-item>
			</div>
			<div v-for="(input, index) in gzsjItems[`${triggerGzsj}`]" :key="index" style="margin-top: 10px;width:92%;display: flex;align-items: center;">
			  <el-text class="mx-1" type="primary" style="width: 96px;">序号{{index+1}}</el-text>
			  <el-input v-model="input.name" placeholder="请输入星期"></el-input>&nbsp;
			  <el-input v-model="input.desc" placeholder="请输入时间段"></el-input>&nbsp;
			  <el-icon><Minus /></el-icon>
			</div>
			<div style="display: flex;justify-content: center;margin-top: 20px;">
				<el-form-item>
			      <el-button type="primary" @click="onSubmit">保存</el-button>
			    </el-form-item>
			</div>
		</el-form>
	</div>
</template>
<style scoped>
	.avatar-uploader .avatar {
	  width: 128px;
	  height: 128px;
	  display: block;
	}
	.avatar-uploader .el-upload {
	  border: 1px dashed var(--el-border-color);
	  border-radius: 6px;
	  cursor: pointer;
	  position: relative;
	  overflow: hidden;
	  transition: var(--el-transition-duration-fast);
	}
	.avatar-uploader .el-upload:hover {
	  border-color: var(--el-color-primary);
	}
	.el-icon.avatar-uploader-icon {
	  font-size: 28px;
	  color: #8c939d;
	  width: 178px;
	  height: 178px;
	  text-align: center;
	}
</style>